import { Box } from "@storyofams/react-ui";
import styled from "styled-components";

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 16px;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(10, 1fr);
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

export default Grid;