import { storyblokEditable } from '@storyblok/react';
import { Box, SystemProps, Flex } from '@storyofams/react-ui';

import { Heading, Button, Container } from '~components';
import { getLinkProps } from '~lib';

import { DynamicSectionContainer } from '..';
import { CaseItem } from '../../CaseItem';
import { useMemo } from 'react';
import Grid from '~components/common/Grid';

type CasesSectionProps = {
  content: any;
  first?: boolean;
} & SystemProps;

export const CasesSection = ({
  content,
  ...props
}: CasesSectionProps) => {
  const { bg, cases_type, title, bold_title, featured, cases, call_to_action_label, call_to_action_link } = content;

  const [formattedLinks, featuredLink]: [Array<Parameters<typeof CaseItem>[0]>, Parameters<typeof CaseItem>[0]] = useMemo(
    () => {
      return [
        cases.map((item: any) => {
          return formatLink(item, cases_type)
        }), 
        formatLink(featured, cases_type)
      ];
    },
    [cases, featured]
  );

  return (
    <LinkSection
      bg={bg}
      cases_type={cases_type}
      title={title} bold_title={bold_title}
      featured={featuredLink}
      cases={formattedLinks}
      call_to_action={{
        label: call_to_action_label,
        link: call_to_action_link
      }}
      {...props}
      editable={storyblokEditable(content)}
    />
  );
}

type CasesHighlightedGridProps = {
  featured: Parameters<typeof CaseItem>[0];
  cases: Array<Parameters<typeof CaseItem>[0]>;
  call_to_action: {
    label: string;
    link: string;
  };
} & SystemProps;


export const CasesHighlightedGrid = ({ featured, cases, call_to_action }: CasesHighlightedGridProps) => {
  return (
    <>
      <CaseItem {...featured} isFeatured gridColumn={["span 6", "span 8", "span 10", "span 6"]} gridRow={"span 2"}/>

      {cases.map((item) => (
        <CaseItem {...item} gridColumn={["span 6", "span 8", "span 10", "span 6"]} />
      ))}

      <Button
        display="block"
        variant="primary"
        mr="auto !important"
        ml="auto !important"
        width="max-content"
        href={getLinkProps(call_to_action.link)}
        gridColumn={["span 6", "span 8", "span 10", "span 12"]}
      >
        {call_to_action.label}
      </Button>
    </>
  );
};

type CasesThreeOnRowGridProps = {
  cases: Array<Parameters<typeof CaseItem>[0]>;
  cases_type: string;
} & SystemProps;

export const CasesThreeOnRowGrid = ({ cases, cases_type }: CasesThreeOnRowGridProps) => {
  return (
    <Flex gap={[1, 4]} mb={[4, 10]} flexDirection={['column', 'column', 'row']} justifyContent={['space-between','space-between','start', 'start']}
          flex={['100%', '100%', '33%']} flexShrink={0} gridColumn={"span 12"}>
      {cases.map((item) => (
        <CaseItem {...item} casesType={cases_type}/>
      ))}
    </Flex>
  );
};

type LinkSectionProps = {
  first?: boolean;
  bg: string;
  cases_type: string;
  title: string;
  bold_title: string;
  featured: Parameters<typeof CaseItem>[0];
  cases: Array<Parameters<typeof CaseItem>[0]>;
  call_to_action: {
    label: string;
    link: string;
  };
  editable: ReturnType<typeof storyblokEditable>;
} & SystemProps;


export const LinkSection = ({
  first,
  bg,
  cases_type,
  title,
  bold_title,
  featured,
  cases,
  call_to_action,
  ...props
}: LinkSectionProps) => {
  return (
    <DynamicSectionContainer
      {...props}
      bg={bg}
    >
      <Container
        mt={[5, 5, 10]}
        mb={[5, 5, 10]}
      >
        <Grid gridGap={"48px"}>
          <Heading
            fontSize={['26px', '26px', '40px']}
            lineHeight="140%"
            maxWidth={cases_type === 'highlighted_grid' ? '100%' : '50%'}
            color={'black'}
            mb={[4, 4, 7]}
            gridColumn={["span 6", "span 8", "span 10"]}
          >
            {cases_type === 'highlighted_grid' ?
              <>
                {title}{' '}
                <Box as="span" fontWeight="bold">
                  {bold_title}
                </Box>
              </> :
              <>
                <Box as="span" fontWeight="bold">
                  {bold_title}{' '}
                </Box>
                {title}
              </>
            }
          </Heading>
          {cases_type === 'highlighted_grid' ? (
            <CasesHighlightedGrid 
              featured={featured} 
              cases={cases} 
              call_to_action={{
                label: call_to_action.label,
                link: call_to_action.link
              }}
            />
          ) : <CasesThreeOnRowGrid cases={cases} cases_type={cases_type}/>}
        </Grid>
      </Container>
    </DynamicSectionContainer>
  )
};

const formatLink = (item: any, cases_type: "string"): Parameters<typeof CaseItem>[0] => {
  if (!item) return null;
  if (!item.content?.component) return null;
  switch (item.content.component) {
    case "DynamicPage":
      return item.content.body[0].component === "hero-large" ? {
          isFeatured: false,
          casesType: cases_type,
          full_slug: item.full_slug,
          content: {
              case: [{
              ...item.content.body[0],
              thumbnail: item.content.body[0].visual[0].image,
              summary: item.content.body[0].text,
            }]
          }
        } : null
      
    case "case_page":
      return {
        isFeatured: false,
        casesType: cases_type,
        full_slug: item.full_slug,
        content: item.content
      }
  
    default:
      break;
  }
}