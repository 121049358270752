import { Flex, SystemProps, css } from "@storyofams/react-ui";
import { Image as ToolkitImage } from "@storyofams/storyblok-toolkit";
import { first } from "lodash";

import { Button } from "~components/common/Button";
import { Heading } from "~components/common/Heading";

import { Text } from "~components/common/Text";
import { FixedRatio } from "./common/FixedRatio";
import { TruncatedText } from "./common/Navigation/components/List";
import { HoverArea, ScaleOnHover } from "./common/ScaleOnHover";
import { useTranslations } from "~context";
import { useMemo } from "react";
import styled from "styled-components";

type CaseItemProps = {
  isFeatured?: boolean;
  casesType?: string;
  full_slug?: string;
  content: any;
} & SystemProps;

const FeaturedCaseCard = styled.div`
  aspect-ratio: 576 / 522;
  width: 100%;
  height: 100%;

  & .image {
    aspect-ratio: 576 / 350;
    position: relative;
    overflow: hidden;
    flex-grow: 0;
    flex-shrink: 0;
  }

  & .text {
    padding: 24px;
    padding-bottom: 32px;
  }
`;

const CaseCard = styled.div`
  aspect-ratio: 576 / 267;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;

  & .image {
    aspect-ratio: 202 / 267;
    position: relative;
    overflow: hidden;
    flex-grow: 0;
    flex-shrink: 0;
  }

  & .text {
    padding: 24px 32px;
  }
`;

export const CaseItem = ({
  isFeatured = false,
  casesType = "highlighted_grid",
  full_slug,
  content,
  ...props
}: CaseItemProps) => {
  const caseData: Record<string, any> = first(content?.case);
  const { translations } = useTranslations();
  
  const slug = useMemo(() => {
    if (typeof full_slug !== 'string' || full_slug === 'undefined' || full_slug === 'null') {
      return '/';
    }
    return full_slug[0] === '/' ? full_slug : `/${full_slug}`;
  }, [full_slug]);

  const Card = isFeatured ? FeaturedCaseCard : CaseCard;
  return (
    casesType === "highlighted_grid"
      ?
      <HoverArea flex={isFeatured ? 1 : [1, 1, 0]} {...props}>
        <Button
          as="a"
          href={slug}
          variant="unstyled"
          bg="white"
          width="100%"
          minHeight={isFeatured ? 'auto' : ['auto', 'auto', '200px']}
          flexDirection={isFeatured ? "column" : "row"}
          height={isFeatured ? "100%" : null}
          alignItems={"stretch"}
        >
          <Card>
            {caseData?.thumbnail?.filename && (
              <div className="image">
                <ScaleOnHover>
                  <ToolkitImage
                    width="100%"
                    height="100%"
                    alt={caseData?.thumbnail?.alt || ""}
                    src={caseData?.thumbnail?.filename}
                    fit="cover"
                    fluid={600}
                  />
                </ScaleOnHover>
              </div>
            )}

            <div className="text">
              <TruncatedText color="inherit" lineClamp={2} maxWidth="unset">
                <Heading
                  variant="h3"
                  fontSize={isFeatured ? [2, 5] : [1.5, 3]}
                  maxWidth={"100%"}
                  fontWeight={isFeatured ? "light" : "light"}
                  lineHeight="140%"
                  color="black"
                  padding="0"
                  css={css({
                    "& strong": {
                      fontWeight: "bold",
                    },
                  })}
                >
                  <strong>{caseData?.title}</strong> {caseData?.bold_title}
                </Heading>
              </TruncatedText>
              <TruncatedText maxWidth="none">
                <Text
                  mt={1.5}
                  variant="s"
                  fontSize={[1.5, 1.5, 1.75]}
                  position="relative"
                  overflow="hidden"
                  css={css({
                    display: '-webkit-box',
                    textOverflow: 'ellipsis',
                    '-webkit-line-clamp': ['3'],
                    '-webkit-box-orient': 'vertical',
                  })}
                >
                  {caseData?.summary}
                </Text>
              </TruncatedText>
            </div>
          </Card>
        </Button>
      </HoverArea> :
      <HoverArea flex={["100%", "100%", "100%", "0 1 50%", "0 1 33%"]} {...props}>
        <Button
          as="a"
          href={slug}
          variant="unstyled"
          bg="transparent"
          width="100%"
          flexDirection={"column"}
          height={"100%"}
        >
          <FixedRatio
            maxWidth={"100%"}
            width="100%"
            height="auto"
            overflow="hidden"
            ratio={
              [
                [343, 160],
                [576, 350],
              ]
            }
          >
            {caseData?.thumbnail?.filename && (
              <ScaleOnHover>
                <ToolkitImage
                  width="100%"
                  height="100%"
                  alt={caseData?.thumbnail?.alt || ""}
                  src={caseData?.thumbnail?.filename}
                  fit="cover"
                  fluid={600}
                />
              </ScaleOnHover>
            )}
          </FixedRatio>
          
          <Flex
            flexDirection="column"
            pt={3}
            px={0}
            pb={4}
            mt={["auto", "auto", "auto", "unset"]}
            mb={["auto", "auto", "auto", "unset"]}
            width={"100%"}
          >
            <Heading
              variant="h2"
              fontSize={[2, 3]}
              fontWeight={"medium"}
              lineHeight="140%"
              color="black"
              maxWidth="500px"
            >
              {caseData?.title}
            </Heading>
            
            <Text
              mt={1.5}
              variant="s"
              fontSize={[1.5, 1.5, 2]}
              position="relative"
              overflow="hidden"
              maxWidth="500px"
              css={css({
                display: "-webkit-box",
                textOverflow: "ellipsis",
                "-webkit-box-orient": "vertical",
              })}
            >
              {caseData?.summary}
            </Text>
            
            <Button
              variant="link"
              color="black"
              mt={5}
              flexShrink={0}
            >
              {translations?.discover}
            </Button>
          </Flex>
        </Button>
      </HoverArea>
  );
};
